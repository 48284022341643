/**
 * All environment variables accessible from JavaScript Side
 */
import {Environment} from "../../index";

export const ACTIVE_ENVIRONMENT: Environment = {
    name: "STAGE",
    config: {
        apiKey: "AIzaSyDQQWBu9aHtjIze7WY_yFHKMK6Awcpdnag",
        authDomain: "meeter-stage-5f114.firebaseapp.com",
        databaseURL: "https://meeter-stage-5f114-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "meeter-stage-5f114",
        storageBucket: "meeter-stage-5f114.appspot.com",
        messagingSenderId: "183563958831",
        appId: "1:183563958831:web:ab91c147ed5607d020a4da",
        measurementId: "G-5FXXVJ1GC5"
    }
};
